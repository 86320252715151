import React from "react"

import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"

import OgImage from "../images/og-image.jpg"

const NotFoundPage = ({ location }) => (
  <Layout pagePath={location.pathname}>
    <SEO
      title="404 – Sidan kunde inte hittas"
      ogImage={OgImage}
      pagePath={location.pathname}
    />
    <Hero title="404" pagePath={location.pathname} />

    <div className={"section section--text section--background-color-white"}>
      <div className="section__container u-wrap u-wrap--medium">
        <h2 className="section__heading heading heading--small">
          Sidan kan tyvärr inte hittas.
        </h2>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
